/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.74); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal appears on top */
}

/* Popup Content */
.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup-content .popupSteps1,
.popup-content .popupSteps2,
.popup-content .popupSteps3 {
    color: #0056B3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.packageInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-radius: 35px;
    background-color: #0056B3;
    background-image: url('/public/images/packageOpen.webp');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width */
}

.packageInfo .packInfo {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    color: #e0e0e0;
    text-align: center;
    font-weight: 400;
}

.rate-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 30px;
    padding: 10px;
    width: 100%; /* Make width responsive */
    max-width: 450px;
    box-sizing: border-box; /* Ensure padding is included in width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.233);
}

.rate-display .rateDis {
    color: #e3eafa;
    font-size: 15px;
}

.checkbox-flex {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically center both the checkbox and label */
    justify-content: center; /* Horizontally center the entire section */
    gap: 5px; /* Adjust the gap between checkbox and label */
    margin-top: 20px;
}

.checkbox-flex input[type="checkbox"] {
    margin: 0; 
    transform: scale(1.2); 
    margin-left: 200px;
}

.checkbox-flex .termsLabel2 {
    font-size: 13px;
    color: #4d4d4d; /* Ensure text color is appropriate */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    text-align: left; /* Align text to the left */
    margin-right: 200px;
    white-space: nowrap;
}

/* Progress Bar */
.progress-bar {
    background-color: #e0e0e0;
    border-radius: 20px;
    height: 10px;
    width: 100%;
    margin-bottom: 30px;
}

.progress-bar-fill {
    background-color: #0056B3;
    height: 100%;
    border-radius: 20px;
    transition: width 0.3s ease-in-out;
}
.popup-content label{
    font-size: 15px;
    color: #104163;
    font-weight: 500;
}

/* Buttons */
.btnNext,
.btnBack,
.btnSubmit {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 15px;
    width: 90px;
    border-radius: 25px;
    color: #e0e0e0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17px;
    font-weight: 400;
    transition: background-color 0.3s ease-in-out;
}

.btnNext {
    background-color: #0056B3;
    
}
.btnNext:hover {
    background-color: #0590e0;
}

.btnBack {
    background-color: #0a4380;
}
.btnBack:hover {
    background-color: #05a2e0;
}

.btnSubmit {
    background-color: #0858ad;
}
.btnSubmit:hover {
    background-color: #05a2e0;
}

.btnStep2-container,
.btnStep3-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 17px;
    gap: 10px;
}

.radio-address {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically aligns radio button and text */
    margin-bottom: 10px;
    padding: 10px; 
    background-color: #536a96;
    border-radius: 25px;
  }
  
  .radio-address input {
    margin-right: 5px; /* Reduced space between radio button and address text */
  }
  
  .radio-address label {
    display: flex;
    align-items: center;
    
  }
  
  .radio-address span {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color:#ffffff ;
  }
  
  
  

/* Close Button */
.closeSteps-btn {
    position: relative;
    display: flex;
    justify-self: right;  
    font-size: 35px;   
    cursor: pointer;
    color: #333;
    background: none;    
    border: none;        
}

.closeSteps-btn:hover {
    color: #e60000; /* Hover effect */
}

/* Responsive Adjustments for Tablets and Mobile Devices */
@media screen and (max-width: 768px) {
    .packageInfo {
        background-size: 50%; /* Adjust background image size */
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .packageInfo p,
    .rate-display p {
        font-size: 14px; /* Reduce font size */
    }

    .rate-display {
        padding: 8px;
    }

    .checkbox-flex input[type="checkbox"] {
        margin-left: 50px; /* Reduced margin for smaller screens */
    }

    .checkbox-flex .termsLabel2 {
        margin-right: 50px; /* Adjust margin for smaller screens */
        font-size: 12px; /* Slightly smaller font size */
    }

    .btnNext,
    .btnBack,
    .btnSubmit {
        width: 80px; /* Smaller button size */
        font-size: 15px;
        padding: 12px;
    }
}

/* Media query for mobile devices (576px and smaller) */
@media screen and (max-width: 576px) {
    .checkbox-flex {
        flex-direction: column; /* Stack the checkbox and label vertically on small screens */
        align-items: flex-start; /* Align items to the left */
    }

    .checkbox-flex input[type="checkbox"] {
        margin-left: 0; /* Remove margin on mobile devices */
        transform: scale(1); /* Adjust checkbox size for smaller screens */
    }

    .checkbox-flex .termsLabel2 {
        margin: 0; /* No margin for mobile devices */
        font-size: 12px; /* Smaller font for mobile */
    }

    .packageInfo {
        background-size: 40%; /* Further adjust background image size */
        padding: 10px; /* Reduce padding even more for mobile */
    }

    .packageInfo p,
    .rate-display p {
        font-size: 12px; /* Further reduce font size for mobile */
    }

    .btnNext,
    .btnBack,
    .btnSubmit {
        width: 75px; /* Smaller button size */
        font-size: 14px;
        padding: 10px;
    }
}
