.dashboard-container {
  background-image: url('/public/images/profilebg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  flex-direction: column;
}

/* Welcome Section */
.welcome-section {
  background-image: url('/public/images/container_bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.welcomehead-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #0f2853;
  border-radius: 50px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-image: url('/public/images/dashboardhead_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40vh;
  width: 100%;
}

.welcome-section h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #bcd2f8;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.welcome-section p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 3px;
  font-weight: 500;
  color: #104163;
}

.address-card-dashbaord {
  background-color: #dfdcdc;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  font-size: 19px;
  text-align: left;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.address-card-dashbaord p {
  margin: 5px 0;
  font-size: 14px;
  color: #104163;
}
.type-container{
  display: flex;
  justify-content: space-around; 
  gap: 15px; 
  flex-wrap: wrap;
}
.shopOnline-btn, .shopping-request-btn {
  background: linear-gradient(4deg, #104163, #6fc7ff);
  color: #f5f5f5;
  padding: 12px 24px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 35px;
}

.shopOnline-btn:hover, .shopping-request-btn:hover{
  background: linear-gradient(4deg, #0e7bc9, #024a77);
}

.search-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
}

.search-bar {
  padding: 10px;
  width: 40%;
  max-width: 300px;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.status-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.status-filter select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('/public/images/downArrow.png') no-repeat right 10px center;
  background-size: 15px;
  padding: 10px 35px 10px 15px;
  text-align-last: center;
  border-radius: 20px;
  background-color: #104163;
  color: #b8d8ec;
  border: none;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  outline: none;
  transition: background-color 0.3s ease;
  width: auto;
  min-width: 150px;
}

.status-filter select:hover {
  background-color: #08314c;
}

.status-filter option {
  background-color: #ffffff;
  color: #333333;
}

.info-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.info-error p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #104163;
}

.info-error-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.info-error-items p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #a1ddfd;
  margin-bottom: 15px;
}

.shopping-requests-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopping-requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Make cards responsive */
  grid-gap: 15px;
  width: 100%;
}

.range-info {
  text-align: right;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #2c2c2c;
  margin-bottom: 10px;
  margin-right: 10px;
}

/*Shppoing Cards Pagination Styles*/
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background: linear-gradient(4deg, #104163, #6fc7ff);
  padding: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-container .page-btn {
  background-color: rgba(18, 36, 77, 0.555);
  border: 1px solid #f8f8f8;
  color: #f2f4f5;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%; /* Make buttons circular */
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 24px; /* Adjust line-height to vertically center numbers */
  transition: background-color 0.3s;
}

.pagination-container .page-btn:hover {
  background-color: #f2f4f5;
  color: rgba(18, 36, 77, 0.555);
  font-weight: 700;
}

.pagination-container .page-btn.active {
  background-color: #f2f4f5;
  color: rgba(18, 36, 77, 0.555);
  font-weight: 700;
}

.pagination-container .page-btn:disabled {
  cursor: not-allowed;
  background-color: #35020231;
}

.pagination-container .arrow-btn {
  background-color: rgba(18, 36, 77, 0.555);
  border: 1px solid #f8f8f8;
  color: #f2f4f5;
  font-size: 15px;
  margin: 0 5px;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.pagination-container .arrow-btn:hover {
  background-color: #f2f4f5;
  color: rgba(18, 36, 77, 0.555);
}

.pagination-container .arrow-btn:disabled {
  cursor: not-allowed;
  background-color: #00000031;
  color: #f2f4f5;
  border: 1px solid #f8f8f8;
}

/*Packages Table Pagination*/
.pagination-containerPackages {
  display: flex;
  width: 100%;
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  background: linear-gradient(4deg, #104163, #6fc7ff);
  padding: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.pagination-containerPackages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pagination-containerPackages .page-btn {
  background-color: rgba(18, 36, 77, 0.555);
  border: 1px solid #f8f8f8;
  color: #f2f4f5;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%; /* Make buttons circular */
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 24px; /* Adjust line-height to vertically center numbers */
  transition: background-color 0.3s;
}

.pagination-containerPackages .page-btn:hover {
  background-color: #f2f4f5;
  color: rgba(18, 36, 77, 0.555);
  font-weight: 700;
}

.pagination-containerPackages .page-btn.active {
  background-color: #f2f4f5;
  color: rgba(18, 36, 77, 0.555);
  font-weight: 700;
}

.pagination-containerPackages .page-btn:disabled {
  cursor: not-allowed;
  background-color: #35020231;
}

.pagination-containerPackages .arrow-btn {
  background-color: rgba(18, 36, 77, 0.555);
  border: 1px solid #f8f8f8;
  color: #f2f4f5;
  font-size: 15px;
  margin: 0 5px;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.pagination-containerPackages .arrow-btn:hover {
  background-color: #f2f4f5;
  color: rgba(18, 36, 77, 0.555);
}

.pagination-containerPackages .arrow-btn:disabled {
  cursor: not-allowed;
  background-color: #00000031;
  color: #f2f4f5;
  border: 1px solid #f8f8f8;
}


.shopping-requests-section {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;
}

.shopping-requests-section h2 {
  font-size: 35px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #104163;
}

.shopping-request-card {
  background-color: #2d6a93;
  border-radius: 35px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.233);
  padding: 20px;
  background-image: url('/public/images/packageIcon.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  border-left: 8px solid #6fc7ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}


.request-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 10px;
  margin-bottom: 15px;
  width: fit-content;
  background-color: #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.request-header p {
  margin: 0;
  font-size: 16px;
  color: #104163;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.request-details p {
  margin: 0;
  color: #f3f3f3;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

.request-footer {
  margin-top: 15px;
  text-align: center;
}

.view-details-btn {
  background-color: #6fc7ff;
  color: #2d6a93;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.view-details-btn:hover {
  background-color: #e5e7e5;
}

/* Modal Styling for Shipment Details */
.modal-details {
  background-color: white;
  padding: 40px;
  border-radius: 45px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.432);
  width: 800px;
  max-width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
  border-top: 8px solid #0056b3;
  position: relative; /* Set position relative to contain the close button */
}


.modal-details h2 {
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0056b3;
  margin-bottom: 30px;
  font-weight: 700;
}

.info {
  font-size: 18px;
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #0056b3;
}

.modal-shipment-details {
  text-align: left;
  font-size: 16px;
}

.modal-details-title p {
  margin-bottom: 40px;
  font-size: 20px;
  color: #007bff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
}
} 

.modal-shipment-details-bg {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  background-color: #0056b3;
  max-width: 100%;
  border-radius: 25px;
  border-left: solid 5px #bcd2f8;
  height: 130px;
  background-image: url('/public/images/package.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.modal-shipment-details-bg p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #bcd2f8;
  text-align: center;
}

.modal-shipment-details h3 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  color: #0056b3;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: bold;
}

/* RequestTable.css */
.table-container {
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.247);
  border-radius: 15px;
  border: solid 1px white;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
  
  
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table tr th {
  color: #bcd2f8;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #083f7a;
  
  
  
}

.table th,
.table td {
  padding: 12px;
  width:fit-content;
}

.table td a {
  color: #051f38;
  text-decoration: none;
  
  
}

.table tr td {
  color: #051f38;
  text-decoration: none;
}

.table td a:hover {
  text-decoration: underline;
}

.total-price-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #083f7a;
  border-radius: 20px;
}

.total-price-summary p1 {
  font-size: 16px;
  margin-top: 10px;
  color: #eaeaea;
}

.total-price-summary p2 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #bcd2f8;
}

.icon_payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.icon_payment h3 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 25px;
}

.payment-instructions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-instructions {
  justify-content: center;
  background-color: #ddeffd;
  width: 70%;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.288);
}
.payment-instructions p{
  margin-left: 10px;
  margin-right: 10px;
}

.payment-instructions p {
  margin-top: 15px;
  margin-bottom: 15px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #a7a7a7;
  border-radius: 10px;
  margin-top: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.progress-bar-pay {
  height: 100%;
  background-color: #0056b3;
  border-radius: 10px;
  text-align: center;
  color: white;
  line-height: 20px;
  font-size: 12px;
  
  transition: width 0.3s ease;
}
.btnParentContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;

}
.upload-btn-container {
  display: flex;
  flex-direction: row;
  background-color: #112151;
  width: 100px;
  justify-content: center;
  margin-top: 15px;
  border-radius: 20px;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.upload-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #e0e0e0;
}
.modalclose-btn{
  background-color: #9e0707;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #e0e0e0;
  border-radius: 20px;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  margin-top: 15px;
  width: 100px;
}
.modalclose-btn:hover{
  background-color: #d10a0a;
}

.upload-btn-container:hover {
  background-color: #0056b3;
}

.edit-request-btn {
  background-color: #e23213;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
  padding: 12px 24px;
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  transition: background-color 0.3s ease;
}

.edit-request-btn:hover {
  background-color: #fa3815;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.801);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.item-entry {
  margin-bottom: 10px;
}

.add-links {
 

}

.close-modal {
  position: absolute;
  top: 15px; /* Adjust the top position */
  right: 15px; /* Adjust the right position */
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #555;
  z-index: 10;
  transition: color 0.2s ease;
}

.close-modal:hover {
  color: #e60000;
}
.terms-and-conditions{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.terms-and-conditions label {
 margin-right: 230px;
 white-space: nowrap;
  color: #0056b3;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.terms-and-conditions input {
  margin-left: 200px;
}

.terms-and-conditions label {
  
}

.terms-and-conditions label a:hover {
  text-decoration: underline;
  color: #e74032;
}

.delete-btn {
  margin-top: 10px;
  margin-left: 5px;
}

.modal h3 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}

.item-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.item-entry p {
  margin-right: 10px;
  font-weight: bold;
}

.item-entry input {
  flex-grow: 1;
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 25%;
  width: 50%;
  margin-top: 30px;
  gap: 20px;
}

.add-more {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease;
  display: inline-block;
  background-color: #0056b3;
}

.add-more:hover {
  background-color: #23a9f2;
}

.request-btn {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease;
  display: inline-block;
  background-color: #0056b3;
}

.request-btn:hover {
  background-color: #248f41;
}

/* Packages Button */
.packages_buttoon {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(4deg, #104163, #6fc7ff);
  border-radius: 45px;
  padding: 10px 10px; /* Adjust padding */
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
  flex-wrap: wrap;
}

.footer-item {
  background-color: rgb(26, 201, 201);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0px 5px rgba(0, 0.2, 0, 0.1);
  font-weight: 600;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  margin: 0 10px;
  flex: 1 1 auto; 
  text-align: center; 
  transition: background-color 0.3s ease;
}
.packages-popup-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.packages-popup-content h2{
  font-size: 30px;
  color: #266087;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
}

/*filtering*/
.filter-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
  align-self: center;
 
  
}
.filter-container label {
  margin-right: 10px;
}
.filter-container option{
 color: white;
}
.status-filter select option {
  background-color: #ffffff;
  color: #333333;
}

.date-filter input[type="text"],
.date-filter input[type="date"] {
  padding: 8px;
  font-size: 14px;
  border-radius: 35px;
  border: 1px solid #ccc;
}


.reset-filters-btn {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 35px ;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 15px;
  font-size: 14px;
}

.reset-filters-btn:hover {
  background-color: #c9302c;
}


/* Responsive wrapper */
.table-responsive {
  overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
  margin: 0 auto;
  padding: 10px;
}

/* Ensures the table is full width on smaller screens */
.shipping-items-table-flex .shipping-items-table {
  width: 100%;
  max-width: none; /* Remove the max-width constraint */
}

/* Styling the table */
.shipping-items-table {
  
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* Styling the table header */
.shipping-items-table thead {
  background-color: #31709A;
  color: #fff;
}

.shipping-items-table th {
  padding: 12px 15px;
  text-align: left;
  color: #b9dbf1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #b9dbf1;
}

/* Styling the table rows */
.shipping-items-table tbody tr {
  background-color: #f9f9f9;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.shipping-items-table tbody tr:nth-child(even) {
  background-color: #eaf0f6;
}

.shipping-items-table tbody tr:hover {
  background-color: #d0e4f7;
}

/* Styling the table data cells */
.shipping-items-table td {
  padding: 12px 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #ddd;
}

/* For table header and body */
.shipping-items-table th, .shipping-items-table td {
  border-right: 1px solid #ddd;
}

.shipping-items-table th:last-child, .shipping-items-table td:last-child {
  border-right: none;
}

/* Additional styling */
.shipping-items-table {
  border-radius: 12px;
}

.MoreLessContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 35px;
  background-color: #31709A;
  transition: background-color 0.3s ease;
}
.MoreLessContainer:hover{
  background-color: #1b5074;
}

.carousel-item {
  flex: 0 0 auto;
  width: 100%; /* Adjust based on the number of items you want to show per slide */
  scroll-snap-align: start;
  padding: 10px;
  box-sizing: border-box;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.button {
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}



.footer-item:hover {
  background-color: #e0e0e0;
  color: #205a80;
}

.badge {
  background-color: white;
  color: #205a80;
  border-radius: 20px;
  padding: 2px 8px;
  margin-left: 5px;
}

/* Items Tab */
.items-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background-color: #2d6a93;
  padding: 20px;
  border-radius: 40px;
  border-bottom: solid 10px rgb(26, 201, 201);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 800px;
  
}
.desc-tab {
  margin-top: 3px;
  margin-bottom: 30px;
  width: max-content;
  background-color: #e6f7ff;
  padding: 6px;
  border-radius: 15px;
  align-self: center;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #007bff;
}

.dbfilter-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}


.dbfilter-section label {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #d3dce2;
}

.dbfilter-section select {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 200px;
  border-radius: 25px;
  background-color: #0a3d5f;
  color: rgb(211, 211, 211);
  outline: none;
  transition: border-color 0.3s ease;
}

.dbfilter-section select:focus {
  border-color: #005fc4;
}

/* Items Display */
.package-count-container {
  display: flex;
  
  gap: 15px;
  margin-bottom: 20px;
  justify-content: center; /* Center the boxes */
}

.package-count-box {
  background-color: #0056b3;
  color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.package-count-box p {
  margin: 0;
  font-size: 16px;
  
  font-weight: 600;
}

.package-count-box p:first-child {
  font-size: 18px;
  font-weight: 700;
}

.consolidateFilter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:20px;
  
}
/*Search by Tracking No*/
.search-section {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search-section label{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #d3dce2;
}

.search-bar {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
 
}


.location-counts-container {
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #0A3D5F;
  border-radius: 35px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 45%;
  margin: auto;
  border-bottom: 5px solid #7ab1d6;

  
}
.location-counts-container .locp{
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color:#71c3d8;
}

.location-counts-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.location-counts-grid {
  display: flex;
  
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.location-count-box {
  background-color: #57c4d4;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  color: white;
  min-width: 100px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.location-count-box .location {
  font-weight: bold;
  margin-bottom: 5px;
}

.location-count-box .count {
  font-size: 14px;
}

.items-display {
  margin-top: 10px;
}

.items-display ul {
  list-style-type: none;
  padding: 0;
}

.items-display ul li {
  padding: 10px;
  background-color: #e0f7fa;
  border-radius: 22px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #104163;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.205);
  transition: background-color 0.3s ease;
}

.items-display ul li:hover {
  background-color: #b2ebf2;
}

.items-display ul li::before {
  content: '📦 ';
  font-size: 18px;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: left;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-btn:hover {
  color: #e60000;
}

.modal-content h2 {
  margin-bottom: 20px;
}


.ship-items-btn {
  background-color: rgb(26, 201, 201);
  color: white;
  padding: 10px 20px;
  border: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  text-align: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.ship-items-btn:hover {
  background-color: #e0e0e0;
  color: #104163;
}

/* Responsive Adjustments */
@media screen and (max-width: 600px) {
  .modal-details {
    padding: 20px;
    width: 90%;
  }
  .close-modal {
    top: 10px;
    right: 10px;
    font-size: 25px;
  }
  .welcomehead-container {
        height: 150px;
      }
  .welcomehead-container h1{
    font-size: 22px !important;
  }

  .terms-and-conditions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    gap: 10px;
  }

  .terms-and-conditions input {
    margin-left: 1px;
  }
  .terms-and-conditions label {
    margin-right: 10px;
  }
  .shopping-requests-grid {
    grid-template-columns: 1fr; /* One card per row on mobile */
  }
  .items-tab-container{
    width:max-content  ;
  }
  .modal {
    width: 90%;
    padding: 20px;
  }

  .type-container {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
    justify-content: center;
    margin-bottom: 20px;
    
  }
  
  .shopOnline-btn, .shopping-request-btn {
    width: 100%; /* Make buttons full width on small screens */
    margin: 0;
    padding: 12px 24px;
  }

  .address-card {
    width: 100%;
  }

  .item-entry p {
    width: auto;
  }

  .input-error {
    border: 1px solid red;
    background-color: #ffe6e6;
  }

  .footer-item {
    padding: 5px 10px;
    font-size: 14px;
    margin-top:5px;
    margin-bottom: 5px;
  }
}
/* Responsive Design: Adjusts for tablets and smaller screens */
@media (max-width: 1024px) {
  .location-counts-container {
    width: 65%; /* Adjust width for medium-sized screens */
  }

}

@media (max-width: 768px) {
  .location-counts-container {
    width: 85%; /* Adjust width for smaller screens (tablets/landscape phones) */
  }
  .desc-tab {
    width: 80%; /* Slightly narrower on tablets */
    font-size: 13px; /* Slightly smaller font size */
    padding: 8px; /* Moderate padding */
    margin-top: 8px;
  }
  .dbfilter-section {
    flex-direction: column; /* Stack the elements vertically */
    margin-top: 20px;
    margin-bottom: 15px;
  }


  .dbfilter-section label {
    font-size: 14px; /* Adjust font size for smaller screens */
    text-align: center;
  }

  .dbfilter-section select {
    width: 100%; /* Make dropdown take full width */
    max-width: 300px; /* Set a max width for larger devices */
    font-size: 13px; /* Slightly smaller font */
  }
  .shipping-items-table th, 
  .shipping-items-table td {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; /* Reduce padding */
  }
  .type-container{
    display: flex;
    justify-content: space-around; 
    gap: 15px; 
    flex-wrap: wrap;
  }
  .shopOnline-btn, .shopping-request-btn {
    background: linear-gradient(4deg, #104163, #6fc7ff);
    color: #f5f5f5;
    padding: 12px 24px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 35px;
  }
  
  .shopOnline-btn:hover, .shopping-request-btn:hover{
    background: linear-gradient(4deg, #0e7bc9, #024a77);
  }
}

@media (max-width: 480px) {
  .location-counts-container {
    width: 95%; /* Full width for mobile devices */
    border-radius: 25px; /* Slightly reduce border radius for mobile */
    padding: 15px; /* Increase padding for better spacing on mobile */
  }

  .location-counts-container .locp {
    font-size: 14px; /* Reduce font size on mobile for better readability */
  }

  .modal-shipment-details-bg{
    background-image: none;
    height: 160px;
    width: 300px;
  }
  .desc-tab {
    width: 90%; /* Adjust width to take up most of the screen */
    padding: 10px; /* Increase padding for touch interaction */
    font-size: 12px; /* Decrease font size for smaller screens */
    margin-top: 10px; /* Add more space on top */
    margin-bottom: 20px; /* Reduce bottom margin */
    text-align: center; /* Center text for better alignment */
  }
  .dbfilter-section {
    padding: 10px; /* Add padding for better spacing */
    margin-top: 15px;
  }

  .countrySelection {
    gap: 8px; /* Reduce gap further */
  }

  .dbfilter-section label {
    font-size: 12px; /* Smaller font for very small screens */
  }

  .dbfilter-section select {
    width: 50%; /* Ensure full width for dropdown */
    max-width: none; /* Remove max-width to adapt to small screens */
    padding: 6px 10px; /* Reduce padding */
    font-size: 12px; /* Smaller font size */
  }
  .shipping-items-table th, 
  .shipping-items-table td {
    font-size: 12px; /* Further reduce font size */
    padding: 8px; /* Reduce padding further */
  }

  .shipping-items-table {
    border-radius: 8px; /* Slightly smaller radius */
  }
  .payment-instructions{
    width: 80%;
  }
}




@media (max-width: 480px) {
  .table-container {
    padding: 5px; /* Further reduce padding */
  }

  .table th,
  .table td {
    font-size: 12px; /* Further reduce font size */
    padding: 6px;
  }

  .modal-shipment-details-bg{
     background-image: none;
     height: fit-content;
     width: fit-content;
 }
 .packages_buttoon {
  display: flex;
  flex-direction: row; 
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  border-radius: 27px;
  
}

.footer-item {
  width: 100%; /* Make buttons full width */
  max-width: 150px; /* Set a max-width for large devices */
  font-size: 12px; /* Adjust font size for smaller screens */
  flex-grow: 2;
  padding: 10px 10px;
}

.type-container {
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons horizontally */
  justify-content: center;
  margin-bottom: 20px;
  
}

.shopOnline-btn, .shopping-request-btn {
  width: 100%; /* Make buttons full width on small screens */
  margin: 0;
  padding: 12px 24px;
}
}


 @media screen and (max-width: 600px) {
  .dashboard-container {
    padding: 10px;
    flex-direction: column;
  }

  .welcome-section {
    padding: 15px;
    border-radius: 25px;
  }

  .welcomehead-container {
    font-size: 22px;
    padding: 10px;
    height: auto;
  }

  .address-card-dashbaord {
    max-width: 100%;
    font-size: 14px;
    padding: 15px;
  }

  .shopping-requests-grid {
    grid-template-columns: 1fr; /* Single column layout for mobile */
    grid-gap: 10px;
  }

  .pagination-container,
  .pagination-containerPackages {
    padding: 8px;
    border-radius: 15px;
  }

  .pagination-container .page-btn,
  .pagination-containerPackages .page-btn {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }

  .modal-details {
    padding: 20px;
    border-radius: 20px;
    width: 95%;
  }

  .shopping-request-card {
    border-radius: 20px;
    padding: 10px;
  }

  .items-tab-container {
    width: 100%;
    padding: 15px;
  }

  .package-count-container {
    flex-wrap: wrap;
    gap: 10px;
  }

  .location-counts-container {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .location-count-box {
    padding: 8px;
    font-size: 13px;
  }

  .items-display ul li {
    font-size: 14px;
    padding: 8px;
  }

  .modal-shipment-details-bg {
    height: auto;
    padding: 10px;
    width: 100%;
    background-image: none;
    border-radius: 15px;
  }

  .ship-items-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .filter-container {
    flex-direction: column;
    padding: 10px;
  }

  .dbfilter-section {
    flex-direction: column;
    
    gap: 10px;
  }

  .dbfilter-section select {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .dashboard-container {
    padding: 10px 5px;
  }

  .welcomehead-container {
    font-size: 20px;
    text-align: center;
  }

  .pagination-container,
  .pagination-containerPackages {
    flex-wrap: wrap;
    gap: 5px;
  }

  .items-tab-container {
    padding: 10px;
  }

  .package-count-container {
    flex-direction: column;
    gap: 8px;
  }

  .shopping-requests-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
  }

  .modal-content {
    padding: 15px;
    border-radius: 15px;
    width: 90%;
  }

  .close-btn {
    font-size: 20px;
    top: 5px;
    right: 10px;
  }
}

