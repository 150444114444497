/* Admin Panel Container */
.admin-panel-container-main{
  background-image: url('/public/images/profilebg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  flex-direction: column;
}
.admin-panel-container {
  background-image: url('/public/images/container_bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50px;
  text-align: center;
  color: rgb(59, 59, 59);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding:50px;
}

/*Logout button styling*/
.logout-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 20px;
  z-index: 10;
  width: 4%;
  border-radius: 15px;
  background-color: #0077B6;
  transition: background-color 0.3s ease;
  
}

.logOutBtn {
  margin-top: 5px;
  padding: 10px;
  cursor: pointer;
  
}

.logout-container:hover {
  background-color: #e33f31;
}


.heading_admin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #0f2853;
  border-radius: 50px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-image: url('/public/images/adminbg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40vh;
  width: 100%;
}

h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color:#bcd2f8;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.tab-buttons-container {
  display: flex;
  justify-content: space-around; /* Evenly distribute the buttons */
  align-items: center;
  margin-bottom: 0;
  background-color: #E0E0E0;
  padding: 14px;
  width: 90%;
  border-radius: 30px;
  box-shadow: 8px 10px 30px rgba(71, 181, 255, 0.76);
}

.tab-buttons {
  display: flex;
  flex: 1; /* Allow the buttons to take equal space */
}

.tab-buttons button {
  flex: 1; /* Ensure all buttons take equal width */
  max-width: 150px; /* Optional: Set a max width if needed */
  text-align: center;
  background-color: #0077b6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.tab-buttons button:hover {
  background-color: #005f9e;
}

.tab-buttons .active {
  background-color: #ff6347;
}


/* Admin Section Styling */

.admin-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 45px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-top: 8px solid #0056b3;
}
.admin-section h2{
  margin-bottom: 20px;
  color: #005f9e;
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.bank-details-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bank-details-form input{
  width: 50%;
  border-radius: 45px;
}
.bank-details-form label{
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}
.bank-details-form h3{
  font-size: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin-bottom: 40px;
}
.bank-details-form input{
  
}
.existingTitle{
  font-size: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.actionContainer{
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.receivedUpdater{
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: white;
  background-color: #0056B3;
  transition: background-color 0.3s ease;
}
.receivedUpdater:hover{
  background-color: #CA2323;
}
/* Loader */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue color for the spinning part */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*filtering option undet new shipment tab*/
.shippingfilter-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping if space is limited */
  align-items: center;
  justify-content: center;
  gap: 15px; /* Space between items */
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  flex-direction: column; /* Labels above dropdowns or inputs */
  align-items: flex-start;
}

.filter-item select,
.filter-item input {
  width: 200px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 35px;
  font-size: 14px;
  cursor: pointer;
}

.filter-item label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

.btnResetfilter {
  
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin-top: 30px;
  font-size: 14px;
  cursor: pointer;
  background-color:#0077B6;
  padding: 10px 20px;
  border-radius: 35px;
  transition: background-color 0.5s ease;
}

.btnResetfilter:hover {
  background-color: #fa4b4b;
}

@media (max-width: 768px) {
  .shippingfilter-container {
    flex-direction: column; /* Stack items on smaller screens */
    align-items: flex-start;
  }
  .filter-item select,
  .filter-item input {
    width: 100%; /* Full-width inputs for mobile */
  }
}



/* Table for Shopping Requests */

.admin-table {
  width: 100%;
  border-collapse: separate; /* Ensure borders do not collapse for border-radius to work */
  border-spacing: 0; /* Adjust spacing between table cells if needed */
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Add a border to the entire table */
  border-radius: 10px; /* Add rounded corners to the table */
  overflow: hidden; /* Ensure content inside respects the border-radius */
}

.admin-table th, .admin-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-table th {
  background-color: #0077b6;
  color: white;
}

.admin-table td select {
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Add rounded corners to the first and last rows */
.admin-table th:first-child {
  border-top-left-radius: 10px;
}
.admin-table th:last-child {
  border-top-right-radius: 10px;
}
.admin-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.admin-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.btnConfirmContainer{
  background-color: #0056b3;
  padding: 10px;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}
.btnConfirmContainer:hover{
  background-color: #DB2B2B;
}
.btnConfirm{
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


/*Status select and update*/
.statusChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligns children (including the button) to the right */
  
  
}

.btnStatusUpdate {
  margin-top: 8px;
  width: 100%;
  padding: 10px;
  background-color: #0077B6;
  color: white;
  border-radius: 15px;
  margin-left: auto; 
  transition: background-color 0.3s ease;
}
.btnStatusUpdate:hover{
  background-color: #ca2323;
}

/* User Search Section */
.user-search {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.user-search input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  width: 300px;
}

.user-search button {
  background-color: #0077b6;
  color: white;
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.user-search button:hover {
  background-color: #005f9e;
}

/* Suggestions Box for Auto-Suggest */
.suggestions {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  z-index: 100;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

/* User Details Section */
.user-details {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-details p {
  margin: 10px 0;
}

.user-details h3 {
  margin-bottom: 15px;
  color: #2c3e50;
}

/* Add New Packages Popup Modal */
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for focus on modal */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 45px;
  border-top: 8px solid #0056b3;
  width: 800px;
  max-width: 90%;
  max-height: 80vh; /* Set a maximum height relative to the viewport height */
  overflow-y: auto;  /* Enable vertical scrolling */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Added deeper shadow for better visual hierarchy */
  animation: fadeIn 0.3s ease-in-out;
}
.closeIcon {
  position: absolute; /* Position it relative to the modal */
  top: 10px; /* Distance from the top of the modal */
  right: 10px; /* Distance from the right of the modal */
  background: none;
  border: none;
  font-size: 35px;
  cursor: pointer;
  z-index: 10; /* Ensures it stays on top */
  color: #555;
  transition: color 0.2s ease;
}
.closeIcon:hover {
  color: #e60000;
}

/*New Package Btn*/
.newPackageBtnContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
  justify-content: center;
  width: max-content;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #083F7A;
  transition: background-color 0.3s ease;
}
.newPackageBtn{
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.newPackageBtnContainer:hover{
  background-color: #0077B6;
}

/*View Package filters*/
.filter-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 280px;
}

.date-filter {
  display: flex;
  gap: 10px;
}

input[type="text"],
input[type="date"] {
  padding: 8px;
  font-size: 14px;
  border-radius: 35px;
  border: 1px solid #ccc;
}
.resetBtncontainer{
  background-color:#0077B6;
  width: 40%;
  padding: 7px;
  border-radius: 35px;
  transition: background-color 0.3s ease;
}
.clear-filters{
  width: 50%;
  font-size: 14px;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  
}
.resetBtncontainer:hover{
  background-color: #fa4b4b;
}

.hover-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}
.hover-link:hover {
  color: #0077B6 ;
  font-weight: 500;
  font-size: 17px;
}
/*Request popup modal on view packages*/
.modal-content{
  
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.modal-content h2{
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0056b3;
  margin-bottom: 30px;
  font-weight: 700;
}
.modal-content p{
  color: rgb(226, 226, 226);
  font-size: 15px;
}
.contentBG {
  background-image: url('/public/images/boxclose.webp');
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  background-color: #0056B3;
  padding: 20px;
  border-radius: 30px;
  border-top: solid 8px #82beff;
  width: 100%;
  max-width: 400px;  /* Adjust width as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
/*user table styling*/
.user-table thead {
  background-color: #31709A;
  color: #fff;
}

.user-table th {
  padding: 12px 15px;
  text-align: left;
  color: #b9dbf1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #b9dbf1;
}

.user-table {
  border-radius: 12px; /* Rounded corners for the table */
  overflow: hidden; /* Ensures content respects the rounded corners */
  width: 100%;
  border-spacing: 0; /* Avoid spacing between cells */
  border: 1px solid #ddd; /* Add a visible border */
  margin-top: 10px; /* Optional margin for spacing */
}

.user-table thead {
  background-color: #31709A;
  color: #fff;
}

.user-table th {
  padding: 12px 15px;
  text-align: left;
  background-color: #0077B6; /* Header background color */
  color: #f0f0f0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #b9dbf1;
}

.user-table tbody tr {
  background-color: #f9f9f9;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.user-table tbody tr:nth-child(even) {
  background-color: #eaf0f6;
}

.user-table tbody tr:hover {
  background-color: #d0e4f7;
}

.user-table td {
  padding: 12px 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.user-table th, .user-table td {
  border-right: 1px solid #ddd;
}

.user-table th:last-child, .user-table td:last-child {
  border-right: none;
}


/* Inner table styling */

.inner-table {
  border-radius: 8px; /* Rounded corners */
  width: 100%;
  border-spacing: 0; /* Reset spacing between table cells */
  margin-top: 10px;
  overflow: hidden; /* Ensures rounded corners appear by clipping the content */
  border: 1px solid #ddd; /* Add a border to match the overall design */
}

.inner-table thead {
  background-color: #31709A;
  color: #fff;
}

.inner-table th {
  padding: 8px 10px;
  text-align: left;
  background-color: #0077B6;
  color: #f0f0f0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #b9dbf1;
}

.inner-table tbody tr {
  background-color: #60b7e6;
  transition: background-color 0.3s ease;
}

.inner-table tbody tr:nth-child(even) {
  background-color: #eaf0f6;
}

.inner-table tbody tr:hover {
  background-color: #64a3dd;
}

.inner-table td {
  padding: 8px 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.inner-table th, .inner-table td {
  border-right: 1px solid #ddd;
}

.inner-table th:last-child, .inner-table td:last-child {
  border-right: none;
}



.user-search {
  margin-bottom: 20px;
  align-self: center;
 
}

.user-search input {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 35px;
}



.popup-content h3 {
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #0056b3;
  margin-bottom: 20px;
  font-weight: 700;
}

.popup-content .labeltext {
  display: block;
  margin: 15px 0 8px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
}
.popup-content p {
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: #6dc0ec;
  background-color: #0f2853;
  padding: 10px;
  width: 40%; /* Keep the width you want */
  margin: 0 auto; /* This will center the element horizontally */
  text-align: center; /* Optional: centers the text inside the element */
  border-radius: 25px;
}


.popup-content select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-bottom: 15px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.2s;
}
.table tbody input{
  border-radius: 15px;
  color: #083F7A;
}

.popup-content input:focus,
.popup-content select:focus {
  border-color: #0077b6;
  outline: none;
}

.addclose_container{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.btnContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.updateReq_btn{
  padding: 15px 25px;
  width: auto;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  background-color: #0077b6;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.updateReq_btn:hover {
  background-color: #005f9e;
  
}

.updateStatus-button {
  background-color: #0077B6; 
  color: white;
  border: none; 
  border-radius: 20px; 
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer; 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover and active effects */
}

.updateStatus-button:hover {
  background-color: #005F9E; 
  transform: scale(1.05); 
}

.updateStatus-button:active {
  background-color: #1e6a91;
  transform: scale(0.95); 
}

.updateStatus-button:disabled {
  background-color: #bdc3c7; 
  color: #7f8c8d; 
  cursor: not-allowed; 
  transform: none; 
  box-shadow: none; 
}


.approveBtn{
  padding: 15px 25px;
  width: auto;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  background-color: #0d5f18;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.approveBtn:hover{
  background-color: #094e12;
}

.close_btn {
  padding: 15px 25px;
  width: auto;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  background-color: #c5361d;
  transition: background-color 0.3s ease;
  
  
}

.close_btn:hover {
  background-color: #a52e19;
}
.btnSubmit{
  padding: 15px 25px;
  width: auto;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  
  background-color: #af9418;
  transition: background-color 0.3s ease;
}
.btnSubmit:hover{
  background-color: #927a10;
}

.approve_btn {
  padding: 15px 25px;
  width: auto;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  background-color: #15801a;
  transition: background-color 0.3s ease;
}

.approve_btn:hover {
  background-color: #17721b;
}
.reject_btn {
  padding: 15px 25px;
  width: auto;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  background-color: #ff6347;
  transition: background-color 0.3s ease;
}

.reject_btn:hover {
  background-color: #c74718;
}


/* New Styles for URLs and Descriptions Table */
.popup-content .url-description-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.url-description-table th,
.url-description-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.url-description-table th {
  background-color: #0077b6;
  color: white;
}

.url-description-table td a {
  color: #0077b6;
  text-decoration: none;
}

.url-description-table td a:hover {
  text-decoration: underline;
  color: #005f9e;
}

/* Unit Price Input Field */
.url-description-table td input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Animation for popup fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .tab-buttons {
    flex-direction: column;
  }

  .tab-buttons button {
    margin: 10px 0;
  }

  .admin-table th, .admin-table td {
    padding: 10px;
  }


  .user-search {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-search input {
    width: 100%;
    margin-bottom: 10px;
  }
  .popup-content .closeIcon{
    left: 86%;
    font-size: 40px;
    bottom: 75%;
  }
  

  /* Popup adjustments for smaller screens */
  .popup-content {
    width: 90%;
    padding: 20px;
  }

  .popup-content h3 {
    font-size: 1.5rem;
  }

  .popup-content input,
  .popup-content select {
    padding: 8px;
  }

  .popup-content button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  /* Responsive table for URLs and Descriptions */
  .url-description-table th,
  .url-description-table td {
    padding: 8px;
  }
}

/*responsiveness for 480px width devices*/
@media (max-width: 480px) {
  .popup-content .closeIcon {
    
      left: 85%;
      font-size: 40px;
      bottom: 72%;
    
  }
}
